<template>
    <div id="order-statistics-shopSex-chart" style="height: 300px"></div>
</template>

<script>
import echartsOptions from './echartsOptions'
import * as API_Customer from '../../../api/customer'
export default {
  name: 'shopSexCustomer',
  props: ['params', 'curTab'],
  data() {
    return {
      loading: false
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.echarts = this.$echarts.init(document.getElementById('order-statistics-shopSex-chart'))
    })
  },
  activated() {
    this.$nextTick(() => {
      this.echarts = this.$echarts.init(document.getElementById('order-statistics-shopSex-chart'))
    })
  },
  watch: {
    curTab: 'GET_OrderStatisticsOrder',
    params: {
      handler: 'GET_OrderStatisticsOrder',
      deep: true
    }
  },
  methods: {
    /** 获取店铺客户访问量 */
    GET_OrderStatisticsOrder() {
      if (this.curTab !== 'shop' || this.loading) return
      this.loading = true
      // this.$emit('update')
      API_Customer.shopCustomerSex(this.params).then(res => {
        this.loading = false
        let resData = []
        let num = 0
        if (res.length === 0) {
          resData = [
            { value: 0, name: '男' },
            { value: 0, name: '女' }
          ]
        } else {
          res.forEach(item => {
            num += item.sex_sum
            const obj = {}
            obj['value'] = item.sex_sum
            obj['name'] = item.vs_type === 1 ? '男' : '女'
            resData.push(obj)
          })
        }
        this.echarts.setOption({
          title: {
            text: '当前访问店铺客户总数' + num + '人',
            left: 'left'
          },
          tooltip: {
            trigger: 'item',
            formatter: '{b} : {c} ({d}%)'
          },
          series: [
            {
              type: 'pie',
              radius: '55%',
              center: ['50%', '60%'],
              data: resData,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        })
        this.echarts.resize()
      })
    }
  }
}
</script>

<style lang="scss">

</style>
