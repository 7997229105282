<template>
    <div id="order-statistics-shop-chart" style="height: 300px"></div>
</template>

<script>
import echartsOptions from './echartsOptions'
import * as API_Customer from '../../../api/customer'
export default {
  name: 'shopCustomer',
  props: ['params', 'curTab'],
  data() {
    return {
      loading: false
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.echarts = this.$echarts.init(document.getElementById('order-statistics-shop-chart'))
    })
  },
  watch: {
    curTab(newValue, oldValue) {
      if (newValue !== oldValue) {
        console.log(newValue)
        if (newValue === 'shop') {
          this.GET_OrderStatisticsOrder()
        } else {
          this.GET_OrderCustomer()
        }
      }
    },
    params: {
      handler() {
        if (this.curTab === 'shop') {
          this.GET_OrderStatisticsOrder()
        } else {
          this.GET_OrderCustomer()
        }
      },
      deep: true
    }
  },
  methods: {
    /** 获取店铺客户访问量 */
    GET_OrderStatisticsOrder() {
      if (this.curTab !== 'shop' || this.loading) return
      this.loading = true
      this.$emit('update')
      const { cycle_type } = this.params
      API_Customer.shopCustomer(this.params).then(res => {
        this.loading = false
        const { xAxis } = res
        const series0 = res.series[0]
        const series1 = res.series[1]
        this.echarts.setOption(echartsOptions({
          color: ['#c23531', '#2f4554'],
          legend: {
            right: 50,
            data: [series0.name, series1.name]
          },
          xAxisData: xAxis,
          yName: "近7天客户数统计",
          seriesName: '下单数量',
          series: [
            {
              type: 'line',
              name: series0.name,
              data: series0.data
            },
            {
              type: 'line',
              name: series1.name,
              data: series1.data
            }
          ]
        }))
        this.echarts.resize()
      })
    },
    GET_OrderCustomer() {
      if (this.curTab !== 'order' || this.loading) return
      this.loading = true
      const { cycle_type } = this.params
      API_Customer.orderCustomer(this.params).then(res => {
        this.loading = false
        const { xAxis } = res
        const series0 = res.series[0]
        const series1 = res.series[1]
        this.echarts.setOption(echartsOptions({
          color: ['#c23531', '#2f4554'],
          legend: {
            right: 50,
            data: [series0.name, series1.name]
          },
          xAxisData: xAxis,
          yName: "近7天客户数统计",
          seriesName: '下单数量',
          series: [
            {
              type: 'line',
              name: series0.name,
              data: series0.data
            },
            {
              type: 'line',
              name: series1.name,
              data: series1.data
            }
          ]
        }))
        this.echarts.resize()
      })
    }
  }
}
</script>

<style lang="scss">

</style>
