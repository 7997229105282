<template>
    <div id="order-statistics-shopArea-chart" style="height: 500px"></div>
</template>

<script>
import echartsOptions from './echartsOptions'
import * as API_Customer from '../../../api/customer'
import echartsOptionsMap from './echartsOptionsMap';
import echartMapChina from './echart-map-china'
export default {
  name: 'shopAreaCustomer',
  props: ['params', 'curTab'],
  data() {
    return {
      loading: false
    }
  },
  created() {
    this.$echarts.registerMap('china', echartMapChina)
  },
  mounted() {
    this.$nextTick(() => {
      this.echarts = this.$echarts.init(document.getElementById('order-statistics-shopArea-chart'))
    })
  },
  activated() {
    this.$nextTick(() => {
      this.echarts = this.$echarts.init(document.getElementById('order-statistics-shopArea-chart'))
    })
  },
  watch: {
    curTab: 'getShopAreaCustomer',
    params: {
      handler: 'getShopAreaCustomer',
      deep: true
    }
  },
  methods: {
    /** 获取店铺客户访问量 */
    getShopAreaCustomer() {
      if (this.curTab !== 'shop' || this.loading) return
      this.loading = true
      API_Customer.shopCustomerArea(this.params).then(res => {
        this.loading = false
        const { data, name } = res
        const _data = data.map((item, index) => ({ name: name[index], value: data[index] }))
        this.echarts.setOption(echartsOptionsMap({
          seriesName: '访问店铺人数',
          seriesData: _data
        }))
        this.echarts.resize()
      })
    }
  }
}
</script>

<style lang="scss">

</style>
