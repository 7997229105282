<template>
  <div class="page-customer-analysis">
    <el-row type="flex" justify="space-between">
      <!--方框统计 start-->
      <el-col :span="4" class="block">
        <div @click="openCustomerList('follow')" style="cursor: pointer">
          <el-col :span="6" style="text-align: center">
            <i class="el-icon-document" style="font-size: 40px"></i>
          </el-col>
          <el-col :span="18">
            <div style="margin-bottom: 10px">关注本店铺客户数</div>
            <!-- <el-button @click="initGoods">初始化</el-button> -->
            <div>{{ indexData.collection_shop_member_num }}</div>
          </el-col>
        </div>
      </el-col>
      <el-col :span="4" class="block">
        <div @click="openCustomerList('place')" style="cursor: pointer">
          <el-col :span="6" style="text-align: center">
            <i class="el-icon-document" style="font-size: 40px"></i>
          </el-col>
          <el-col :span="18">
            <div style="margin-bottom: 10px">已下单客户数</div>
            <div>{{ indexData.have_order_member_num }}</div>
          </el-col>
        </div>
      </el-col>
      <el-col :span="4" class="block">
        <div @click="openCustomerList('follow_place')" style="cursor: pointer">
          <el-col :span="6" style="text-align: center">
            <i class="el-icon-document" style="font-size: 40px"></i>
          </el-col>
          <el-col :span="18">
            <div style="margin-bottom: 10px">关注店铺未下单客户数</div>
            <div>{{ indexData.not_order_member_num }}</div>
          </el-col>
        </div>
      </el-col>
      <el-col :span="4" class="block">
        <div @click="openCustomerList('collection')" style="cursor: pointer">
          <el-col :span="6" style="text-align: center">
            <i class="el-icon-document" style="font-size: 40px"></i>
          </el-col>
          <el-col :span="18">
            <div style="margin-bottom: 10px">收藏店铺活动客户数</div>
            <div>{{ indexData.collecting_member_num }}</div>
          </el-col>
        </div>
      </el-col>
      <!--方框统计 end-->
    </el-row>
    <!-- 客户群体分析 -->
    <el-tabs v-model="cur_tab" type="card">
      <div class="echartsTitle">
        <p>客户群体分析</p>
        <div>
          <el-button
            :type="day === 0 ? 'danger' : 'primary'"
            size="mini"
            @click="typeChange(0)"
            >最近7天</el-button
          >
          <el-button
            :type="day === 1 ? 'danger' : 'primary'"
            size="mini"
            @click="typeChange(1)"
            >最近1个月</el-button
          >
          <el-button
            :type="day === 2 ? 'danger' : 'primary'"
            size="mini"
            @click="typeChange(2)"
            >最近一年</el-button
          >
          <el-date-picker
            style="width: 280px; margin-left: 10px"
            v-model="time_range"
            type="daterange"
            value-format="timestamp"
            range-separator="~"
            size="mini"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
          ></el-date-picker>
        </div>
      </div>
      <el-tab-pane label="店铺访问客户分析" name="shop">
        <shop-customer :params="params" :cur-tab="cur_tab" />
      </el-tab-pane>
      <el-tab-pane label="下单客户分析" name="order">
        <order-customer :params="params" :cur-tab="cur_tab" />
      </el-tab-pane>
    </el-tabs>
    <!-- 客户性别分析 -->
    <el-tabs v-model="cur_sex_tab" type="card">
      <!-- <div class="echartsTitle">
        <p>客户群体男女比例分析</p>
        <div>
          <el-button
            :type="sex_day === 0 ? 'danger' : 'primary'"
            size="mini"
            @click="sexDayChange(0)"
            >最近7天</el-button
          >
          <el-button
            :type="sex_day === 1 ? 'danger' : 'primary'"
            size="mini"
            @click="sexDayChange(1)"
            >最近1个月</el-button
          >
          <el-button
            :type="sex_day === 2 ? 'danger' : 'primary'"
            size="mini"
            @click="sexDayChange(2)"
            >最近一年</el-button
          >
          <el-date-picker
            style="width: 280px; margin-left: 10px"
            v-model="time_range"
            type="daterange"
            value-format="timestamp"
            range-separator="~"
            size="mini"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
          ></el-date-picker>
        </div>
      </div> -->
      <el-tab-pane label="店铺访问客户分析" name="shop">
        <shop-sex-customer :params="sex_params" :cur-tab="cur_sex_tab" />
      </el-tab-pane>
      <el-tab-pane label="下单客户分析" name="order">
        <order-sex-customer :params="sex_params" :cur-tab="cur_sex_tab" />
      </el-tab-pane>
    </el-tabs>
    <!-- 客户地域分析 -->
    <el-tabs v-model="cur_area_tab" type="card">
      <!-- <div class="echartsTitle">
        <p>客户群体人数</p>
        <div>
          <el-button
            :type="area_day === 0 ? 'danger' : 'primary'"
            size="mini"
            @click="areaDayChange(0)"
            >最近7天</el-button
          >
          <el-button
            :type="area_day === 1 ? 'danger' : 'primary'"
            size="mini"
            @click="areaDayChange(1)"
            >最近1个月</el-button
          >
          <el-button
            :type="area_day === 2 ? 'danger' : 'primary'"
            size="mini"
            @click="areaDayChange(2)"
            >最近一年</el-button
          >
          <el-date-picker
            style="width: 280px; margin-left: 10px"
            v-model="time_range"
            type="daterange"
            value-format="timestamp"
            range-separator="~"
            size="mini"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
          ></el-date-picker>
        </div>
      </div> -->
      <el-tab-pane label="店铺访问客户分析" name="shop">
        <shop-area-customer :params="area_params" :cur-tab="cur_area_tab" />
      </el-tab-pane>
      <el-tab-pane label="下单客户分析" name="order">
        <order-area-customer :params="area_params" :cur-tab="cur_area_tab" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import shopCustomer from "./shopCustomer";
import orderCustomer from "./orderCustomer";
import shopSexCustomer from "./shopSexCustomer";
import orderSexCustomer from "./orderSexCustomer";
import shopAreaCustomer from "./shopAreaCustomer.vue";
import orderAreaCustomer from "./orderAreaCustomer.vue";
import * as API_Customer from "../../../api/customer";

export default {
  components: {
    shopCustomer,
    orderCustomer,
    shopSexCustomer,
    orderSexCustomer,
    shopAreaCustomer,
    orderAreaCustomer,
  },
  data() {
    return {
      // 首页展示数据
      indexData: {
        // 关注店铺客户数
        collection_shop_member_num: 0,
        // 已下单客户数
        have_order_member_num: 0,
        // 关注店铺未下单客户
        not_order_member_num: 0,
        // 收藏店铺的客户数
        collecting_member_num: 0,
      },
      cur_tab: "shop",
      cur_sex_tab: "shop",
      cur_area_tab: "shop",
      // 客户群里分析
      time_range: "",
      day: 0,
      params: {
        cycle_type: "TIME",
        start: "",
        end: "",
      },
      // 客户性别分析
      sex_time_range: "",
      sex_day: 0,
      sex_params: {
        cycle_type: "TIME",
        start: "",
        end: "",
      },
      // 客户地域分析
      area_time_range: "",
      area_day: 0,
      area_params: {
        cycle_type: "TIME",
        start: "",
        end: "",
      },
    };
  },
  watch: {
    time_range(newValue, oldValue) {
      if (newValue !== oldValue) {
        console.log(newValue);
        if (newValue) {
          this.day = 3
          this.params.start = newValue[0] / 1000;
          this.params.end = newValue[1] / 1000 + 86399;

          this.sex_params.start = newValue[0] / 1000;
          this.sex_params.end = newValue[1] / 1000 + 86399;

          this.area_params.start = newValue[0] / 1000;
          this.area_params.end = newValue[1] / 1000 + 86399;
        } else {
          // this.day = 0
          const today = new Date();
          const s = (today.getTime() - today.getHours() * 60 * 60 * 1000 - today.getMinutes() * 60 * 1000 - today.getSeconds() * 1000 -
            today.getMilliseconds()) / 1000;
          this.params.start = s - 6 * 86400;
          this.params.end = s + 86399;

          this.sex_params.start = s - 6 * 86400;
          this.sex_params.end = s + 86399;

          this.area_params.start = s - 6 * 86400;
          this.area_params.end = s + 86399;
        }
      }
    },
    // 弃用
    sex_time_range(newValue, oldValue) {
      if (newValue !== oldValue) {
        console.log(newValue);
        if (newValue) {
          this.sex_params.start = newValue[0] / 1000;
          this.sex_params.end = newValue[1] / 1000;
        } else {
          const today = new Date();
          const s =
            (today.getTime() -
              today.getHours() * 60 * 60 * 1000 -
              today.getMinutes() * 60 * 1000 -
              today.getSeconds() * 1000 -
              today.getMilliseconds()) /
            1000;
          this.sex_params.start = s - 6 * 86400;
          this.sex_params.end = s + 86399;
        }
      }
    },
    area_time_range(newValue, oldValue) {
      if (newValue !== oldValue) {
        console.log(newValue);
        if (newValue) {
          this.area_params.start = newValue[0] / 1000;
          this.area_params.end = newValue[1] / 1000;
        } else {
          const today = new Date();
          const s =
            (today.getTime() -
              today.getHours() * 60 * 60 * 1000 -
              today.getMinutes() * 60 * 1000 -
              today.getSeconds() * 1000 -
              today.getMilliseconds()) /
            1000;
          this.area_params.start = s - 6 * 86400;
          this.area_params.end = s + 86399;
        }
      }
    }
    //
  },
  mounted() {
    const today = new Date();
    const s =
      (today.getTime() -
        today.getHours() * 60 * 60 * 1000 -
        today.getMinutes() * 60 * 1000 -
        today.getSeconds() * 1000 -
        today.getMilliseconds()) /
      1000;
    this.params.start = s - 6 * 86400;
    this.params.end = s + 86399;
    this.sex_params.start = s - 6 * 86400;
    this.sex_params.end = s + 864399;
    this.area_params.start = s - 6 * 86400;
    this.area_params.end = s + 864399;
    this.getIndexData();
    this.GET_OrderStatisticsPage();
  },
  methods: {
    /** 打开客户列表 */
    openCustomerList(type) {
      if (type === "follow") {
        console.log(111);
        this.$router.push({
          name: "客户列表",
          params: {
            'type': type,
          }
        });
      } else if (type === "place") {
        this.$router.push({
          name: "客户列表",
          params: {
            'type': type,
          }
        });
      } else if (type === "follow_place") {
        this.$router.push({
          name: "客户列表",
          params: {
            'type': type,
          }
        });
      } else {
        this.$router.push({
          name: "客户列表",
          params: {
            'type': type,
          }
        });
      }
    },
    getIndexData() {
      API_Customer.getIndexData().then((res) => {
        console.log(res);
        this.indexData.collection_shop_member_num =
          res.collection_shop_member_num;
        this.indexData.have_order_member_num = res.have_order_member_num;
        this.indexData.not_order_member_num = res.not_order_member_num;
        this.indexData.collecting_member_num = res.collecting_member_num;
      });
    },
    GET_OrderStatisticsPage() {
      API_Customer.shopCustomer(this.params).then((res) => {
        console.log(res);
      });
    },
    typeChange(day) {
      this.time_range = ''
      const today = new Date();
      const s =
        (today.getTime() -
          today.getHours() * 60 * 60 * 1000 -
          today.getMinutes() * 60 * 1000 -
          today.getSeconds() * 1000 -
          today.getMilliseconds()) /
        1000;
      if (day === 0) {
        this.day = 0;
        this.params.start = s - 6 * 86400;
        this.params.end = s + 86399;

        this.sex_params.start = s - 6 * 86400;
        this.sex_params.end = s + 86399

        this.area_day.start = s - 6 * 86400;
        this.area_day.end = s + 86399
      } else if (day === 1) {
        this.day = 1;
        this.params.start = s - 29 * 86400;
        this.params.end = s + 86399;

        this.sex_params.start = s - 29 * 86400;
        this.sex_params.end = s + 86399

        this.area_day.start = s - 29 * 86400;
        this.area_day.end = s + 86399
      } else {
        this.day = 2;
        this.params.start = s - 364 * 86400;
        this.params.end = s + 86399;

        this.sex_params.start = s - 364 * 86400;
        this.sex_params.end = s + 86399

        this.area_day.start = s - 364 * 86400;
        this.area_day.end = s + 86399
      }
    },
    sexDayChange(day) {
      const today = new Date();
      const s =
        (today.getTime() -
          today.getHours() * 60 * 60 * 1000 -
          today.getMinutes() * 60 * 1000 -
          today.getSeconds() * 1000 -
          today.getMilliseconds()) /
        1000;
      if (day === 0) {
        this.sex_day = 0;
        this.sex_params.start = s - 6 * 86400;
        this.sex_params.end = s + 86399;
      } else if (day === 1) {
        this.sex_day = 1;
        this.sex_params.start = s - 29 * 86400;
        this.sex_params.end = s + 86399;
      } else {
        this.sex_day = 2;
        this.sex_params.start = s - 364 * 86400;
        this.sex_params.end = s + 86399;
      }
    },
    areaDayChange(day) {
      const today = new Date();
      const s =
        (today.getTime() -
          today.getHours() * 60 * 60 * 1000 -
          today.getMinutes() * 60 * 1000 -
          today.getSeconds() * 1000 -
          today.getMilliseconds()) /
        1000;
      if (day === 0) {
        this.area_day = 0;
        this.area_params.start = s - 6 * 86400;
        this.area_params.end = s + 86399;
      } else if (day === 1) {
        this.area_day = 1;
        this.area_params.start = s - 29 * 86400;
        this.area_params.end = s + 86399;
      } else {
        this.area_day = 2;
        this.area_params.start = s - 364 * 86400;
        this.area_params.end = s + 86399;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-customer-analysis {
  .el-row {
    position: relative;
    margin-bottom: 20px;
  }

  .el-col {
    border-radius: 4px;
  }

  .el-col-4 {
    width: 24%;
  }

  .block {
    background: #fff;
    padding: 25px 0;
  }

  .echartsTitle {
    display: flex;
    justify-content: space-between;
  }

  .el-button {
    width: 70px;
    height: 30px;
    font-size: 12px;
    padding: 0;
  }
}
</style>
