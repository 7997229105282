/**
 * 客户分析相关API
 */

import request from '@/utils/request'

/**
 * 客服分析上方显示数据
 */
export function getIndexData() {
  return request({
    url: '/seller/statistics/reports/getSellerStatistics',
    method: 'get'
  })
}

/**
 * 下单客户地域分析
 */
export function orderArea(params) {
  return request({
    url: 'seller/statistics/reports/increase/memberPayArea',
    method: 'get',
    params
  })
}

/**
 * 下单客户分析
 */
export function orderCustomer(params) {
  return request({
    url: 'seller/statistics/reports/increase/memberPayClass',
    method: 'get',
    params
  })
}

/**
 * 下单客户性别分析
 */
export function orderCustomerSex(params) {
  return request({
    url: 'seller/statistics/reports/increase/memberPaySex',
    method: 'get',
    params
  })
}

/**
 * 店铺客户地域分析
 */
export function shopCustomerArea(params) {
  return request({
    url: 'seller/statistics/reports/increase/memberPvArea',
    method: 'get',
    params
  })
}

/**
 * 店铺客户性别分析
 */
export function shopCustomerSex(params) {
  return request({
    url: 'seller/statistics/reports/increase/memberPvSex',
    method: 'get',
    params
  })
}

/**
 * 店铺客户分析
 */
export function shopCustomer(params) {
  return request({
    url: 'seller/statistics/reports/increase/memberPvClass',
    method: 'get',
    params
  })
}

/**
 * 客户列表
 */
export function membersList(params) {
  return request({
    url: 'seller/statistics/reports/member/list',
    method: 'get',
    params
  })
}
